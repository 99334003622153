<template>
  <section v-if="about">
    <div class="container py-32 md:py-56 lg:py-88">
      <h2 class="text-h-xl md:text-h-2xl font-semibold pb-16 md:pb-0">
        {{ about.title }}
      </h2>
      <div class="flex flex-wrap flex-row-reverse">
        <div class="w-full md:w-1/3 md:pl-8 lg:pl-32 md:-mt-56">
          <div class="w-full bg-neutral-10 rounded-24 flex justify-center">
            <div>
              <img alt="About" :src="about.image || '/static/images/home/about.png'" class="max-w-200 md:max-w-full">
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/3 pt-32 md:pr-8 lg:pr-32 text-center">
          <p v-for="(c, ci) of about.content" :key="ci" class="text-left text-b-m md:text-b-xl md:font-medium md:pb-32">
            {{ c }}
          </p>
          <Link href="/about" class="no-underline text-black">
            <se-button size="large" type="tertiary" class="!px-96 hidden md:block md:visible">
              {{ about.ctaText }}
            </se-button>
            <se-button size="medium" type="tertiary" class="!px-96 block md:hidden ml-0 mt-16">
              {{ about.ctaText }}
            </se-button>
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SeButton from '@/src/shared/ui-kit/button/Button';
import { theme } from '@/theme';
import Link from '@/src/components/Link';

const { about } = theme.home;
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeAbout',
};
</script>
