<template>
  <section v-if="newAdvisors" class="pt-32 pb-56 md:py-56 lg:py-88">
    <div class="container">
      <div class="w-full md:w-10/12 xl:w-6/12 pb-16 md:pb-32">
        <h2 class="text-h-xl md:text-h-2xl font-semibold pb-8 md:pb-16">
          {{ newAdvisors.title }}
        </h2>
        <p class="text-b-s md:text-b-xl font-medium text-neutral-80">
          {{ newAdvisors.description }}
        </p>
      </div>

      <div class="flex flex-wrap -mx-16 gap-y-16 md:gap-y-24 xl:gap-y-40">
        <article v-for="advisor of advisors" :key="advisor.id" class="px-16 w-full md:w-1/2 xl:w-1/3">
          <se-advisor-card :advisor="advisor" />
        </article>
      </div>

      <div class="flex justify-center pt-16 md:pt-32 w-full">
        <Link href="/advisors/new" class="text-black no-underline w-full md:w-auto">
          <se-button size="large" type="tertiary" class="hidden md:inline-flex md:visible">
            {{ newAdvisors.ctaText }}<se-icon name="arrow-right" :size="20" class="ml-6" />
          </se-button>
          <se-button size="medium" type="tertiary" class="inline-flex md:hidden ml-0 w-full">
            {{ newAdvisors.ctaText }}<se-icon name="arrow-right" :size="16" class="ml-6" />
          </se-button>
        </Link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { theme } from '@/theme';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import SeAdvisorCard from '@/src/modules/advisor/components/advisor-card';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeButton from '@/src/shared/ui-kit/button/Button';
import Link from '@/src/components/Link';

const { newAdvisors } = theme.home;

const { $api } = getGlobalProperties();

const advisors = ref<any[]>([]);

const fetchAdvisors = async (onlineOnly: string = '') => {
  await $api.get('/user/advisors', {
    sort: 'registered',
    order: 'desc',
    limit: 6,
    status: onlineOnly,
  })
    .then(async ({ data }: any) => {
      advisors.value = data.data;
      if (advisors.value.length === 0 && !!onlineOnly) {
        await fetchAdvisors();
      }
    }).catch((err: any) => {
      console.log('get advisors error', err);
    });
};

await fetchAdvisors('online');
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeNewAdvisors',
};
</script>
